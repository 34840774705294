export default [
  {
    key: 'year',
    label: 'field.year',
    type: 'year-range-picker',
    cast: 'range',
    searchField: "year",
  },
  {
    key: 'leaveTypeId',
    label: 'field.leaveType',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'leaveType',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'salespersonId',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'positionId',
    label: 'field.position',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'position',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
]
