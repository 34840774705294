export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'year',
    label: 'field.year',
    hideToggle: true,
  },
  {
    key: 'leaveType',
    label: 'field.leaveType',
    hideToggle: true,
  },
  {
    key: 'openBalance',
    label: 'field.openBalance',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'balance',
    label: 'field.balance',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'type',
    label: 'field.type',
    hideToggle: true,
  },
  {
    key: 'remark',
    label: 'field.remark',
    hideToggle: true,
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createdDate',
    hideToggle: true,
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
  },
];
