export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'year',
    label: 'field.year',
    hideToggle: true,
  },
  {
    key: 'leaveTypeName',
    label: 'field.leaveType',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'positionName',
    label: 'field.position',
    localization: true,
  },
  {
    key: 'departmentName',
    label: 'field.department',
    localization: true,
  },
  {
    key: 'balance',
    label: 'field.balance',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'allocateQuantity',
    label: 'field.allocateQuantity',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'forwardQuantity',
    label: 'field.forwardQuantity',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updatedDate',
  },
];
