export default [
  {
    key: 'year',
    label: 'field.year',
    width: 3000,
  },
  {
    key: 'leaveTypeName',
    label: 'field.leaveType',
    localization: true,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 3000,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    width: 7000,
  },
  {
    key: 'positionName',
    label: 'field.position',
    localization: true,
    width: 9000,
  },
  {
    key: 'departmentName',
    label: 'field.department',
    localization: true,
    width: 7000,
  },
  {
    key: 'balance',
    label: 'field.balance',
    width: 3000,
  },
  {
    key: 'allocateQuantity',
    label: 'field.allocateQuantity',
    width: 3000,
  },
  {
    key: 'forwardQuantity',
    label: 'field.forwardQuantity',
    width: 3000,
  },
  {
    key: 'updatedAt',
    label: 'field.updatedDate',
  },
];
