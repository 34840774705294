<template>
  <b-modal
    id="modal-leave-transaction"
    hide-footer
    centered
    :title="title"
    size="xl"
  >
    <b-row>
      <b-col>
        <label>{{ $t("field.entries") }}</label>
        <v-select
          v-model="perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block ml-50 mr-1"
        />
      </b-col>
      <b-col cols="auto" class="text-right">
        <b-row align-h="end">
          <b-col class="pl-0" cols="auto">
            <n-column-visibility
              :fields="fields"
              :visible-columns="visibleColumns"
              v-model="visibleColumns"
              @change="key++"
            ></n-column-visibility>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div>
      <n-table
        ref="table"
        :key="`table-${key}`"
        @sort-changed="sortChanged"
        :visible-columns="visibleColumns"
        :fields="fields"
        :items="items"
        :busy="loading"
        :current-page="query.page"
        :per-page="$store.state.pagination.perPage"
        :total="total"
        :resource="resource"
        :route="route"
        :updatable="false"
        :deletable="false"
      >
        <template #cell(leaveType)="data">
          <span v-if="data.item.leaveType">
            {{ trans(data.item.leaveType, "name", $i18n.locale) }}
          </span>
        </template>
        <template #cell(quantity)="data">
          <span
            :class="{
              'text-positive': data.item.quantity > 0,
              'text-negative': data.item.quantity < 0,
            }"
          >
            {{ data.item.quantity }}
          </span>
        </template>
        <template #cell(type)="data">
          {{ $t(`leaveTransaction.${data.item.type}`) }}
        </template>
      </n-table>
      <n-pagination
        ref="pagination"
        :total="total"
        :per-page="$store.state.pagination.perPage"
        :page="query.page"
        @change="changePage"
      ></n-pagination>
    </div>
  </b-modal>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import NTable from "@/components/NTable";
import NColumnVisibility from "@/components/NColumnVisibility";
import NPagination from "@/components/NPagination";
import TableFields from "./tableFields";
import Repository from "@/repositories/RepositoryFactory";

const LeaveTransactionRepository = Repository.get("leaveTransaction");

export default {
  components: {
    BRow,
    BCol,

    vSelect,

    NTable,
    NColumnVisibility,
    NPagination,
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.leaveTransaction;
      },
      set(value) {
        this.$store.commit("column/SET_LEAVE_TRANSACTION_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  data() {
    return {
      key: 1,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      title: "",
      query: {},
      items: [],
      total: 0,
      loading: false,
    };
  },
  methods: {
    show(item) {
      this.title = `${item?.salespersonName} (${item?.salespersonIdCard})`;
      this.query = {
        page: 1,
        order: "id",
        sort: "DESC",
        query: [
          {
            searchby: "leaveBalanceId",
            searchoperator: "=",
            search: item.id,
          },
        ],
      };
      this.$bvModal.show("modal-leave-transaction");
      this.getData();
    },
    hide() {
      this.$bvModal.hide("modal-leave-transaction");
    },
    getData() {
      this.loading = true;
      LeaveTransactionRepository.index({
        ...this.query,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...TableFields];
    const resource = "leave-balance";
    const route = "leave-balance";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>